import { singleton } from "../../../fable_modules/fable-library.3.7.12/AsyncBuilder.js";
import { sleep } from "../../../fable_modules/fable-library.3.7.12/Async.js";
import { PatientHistoricalComparisonModel, ComparisonLayout, HistoricalViewport, PatientHistoricalComparisonMsg } from "./HistoricalComparisonTypes.js";
import { StateTreeNode$6, NavigationMsg$1, StateTreeMsg$4 } from "../../../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { value as value_28, toArray as toArray_1, some, bind, map, defaultArg } from "../../../fable_modules/fable-library.3.7.12/Option.js";
import { FSharpMap__get_IsEmpty, tryPick, map as map_3, fold, add, ofList, toList as toList_1, empty as empty_2, FSharpMap__get_Item, FSharpMap__ContainsKey, tryFind } from "../../../fable_modules/fable-library.3.7.12/Map.js";
import { dataUrlToBlob, FileMetadata, defaultFrameFile, SessionAnnotationNoteState, LoadingState, SelectedSequence, FileMetadata_get_empty } from "../HistoricalCommon.js";
import { iterate as iterate_1, append, empty, singleton as singleton_1, collect, delay, toList } from "../../../fable_modules/fable-library.3.7.12/Seq.js";
import { collect as collect_1, head, tail, tryFindIndex, removeAt, isEmpty, cons, append as append_1, exists, initialize, updateAt, indexed, tryFind as tryFind_1, ofSeq, choose, map as map_1, concat, iterate, tryItem, toArray, mapIndexed, ofArray, singleton as singleton_2, filter, sortBy, length, empty as empty_1, item as item_3 } from "../../../fable_modules/fable-library.3.7.12/List.js";
import { stringHash, safeHash, uncurry, equals, max, comparePrimitives, min } from "../../../fable_modules/fable-library.3.7.12/Util.js";
import { FlaggedImages_flagFrame, FlaggedImages_tryGetFrameNumber, FlaggedImages_mkFlaggedImageListItemFromSelected, customEvent, Thumbnails_loadThumbnailFromS3, Dicoms_loadDicomFromS3, FlaggedImages_loadFlaggedImagesPromise, Dicoms_loadAndParseFileList, Dicoms_clearDicomCache } from "../HistoricalHelpers.js";
import { Sort_SortInfo$1, Sort_SortDirection } from "../../../Optiscan.SharedUI/Sort.js";
import { compare } from "../../../fable_modules/fable-library.3.7.12/Date.js";
import { flaggedImagesTableContents, flaggedImagesTableColumns } from "../FlaggedImagesView.js";
import { sessionAnnotationsTableCard, sessionAnnotationDeleteModal, sessionAnnotationsEditModal, sessionAnnotationsTableColumns } from "../SessionAnnotationsView.js";
import { Cmd_map, Cmd_ofSub, Cmd_OfPromise_either, Cmd_none, Cmd_batch } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_OfAsyncWith_result, Cmd_OfAsyncWith_attempt, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { PatientsOutboundMsg } from "../../PatientSearchTypes.js";
import { Option, columns } from "../../../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { DOMAttr, CSSProp, HTMLAttr } from "../../../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { keyValueList } from "../../../fable_modules/fable-library.3.7.12/MapUtil.js";
import * as react from "react";
import { Helpers_nothing } from "../../../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { screenshotModal } from "../../ScreenshotModal/ScreenshotModalView.js";
import { ISize, Option as Option_1, column } from "../../../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { Color_IColor, Common_GenericOption, Modifier_IModifier, Screen } from "../../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { content, Header_title, header, card } from "../../../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { FormField_ReactMenu_menuItem, FormField_ReactMenu_subMenu, FormField_ReactMenu_menu, FormField_ReactSelect_CallbackSingle$1, FormField_ReactSelect_single, FormField_ReactSelect_CallbackMulti$1, FormField_ReactSelect_multi } from "../../../Optiscan.SharedUI/Forms.js";
import { End_div, Item_Option, Item_div, Start_div, Option as Option_2, navbar } from "../../../fable_modules/Fulma.2.16.0/Components/Navbar.fs.js";
import { Option as Option_3, button } from "../../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Option as Option_4 } from "../../../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { ThumbnailSliderProps } from "../ThumbnailSliderBindings.js";
import ThumbnailSlider from "../../../../../src/Optiscan.Client/Patients/Historical/js/ThumbnailSlider.tsx";
import { Fa_ISize, Fa_IconOption, Fa_i } from "../../../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { SetFrameEvent, eventNames, IHistoricalViewProps } from "../HistoricalBindings.js";
import HistoricalView from "../../../../../src/Optiscan.Client/Patients/Historical/js/HistoricalView.tsx";
import { map as map_2, unzip, equalsWith } from "../../../fable_modules/fable-library.3.7.12/Array.js";
import { MenuItemProps, SubMenuProps, MenuProps } from "../../../Optiscan.SharedUI/bindings/ReactMenuBind.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library.3.7.12/Choice.js";
import { List_groupBy, List_distinctBy, List_distinct } from "../../../fable_modules/fable-library.3.7.12/Seq2.js";
import { ErrorMessage_get_describe, ErrorMessage } from "../../../Optiscan.Models/ErrorMessage.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { securedApi } from "../../../Api.js";
import { TelemetryAction, FileOperationContext } from "../../../Optiscan.Models/Api/Api.js";
import { isNullOrWhiteSpace, replace } from "../../../fable_modules/fable-library.3.7.12/String.js";
import { Navigation_modifyUrl } from "../../../fable_modules/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { Page, pageHash } from "../../../Pages.js";
import { SessionAnnotationViewModel } from "../../../Optiscan.Models/View/SessionAnnotationViewModel.js";
import { Result_map } from "../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { infoPersistent, error } from "../../../Optiscan.SharedUI/Toast.js";
import { ScreenshotInfoViewModel__omitDataUrls, ScreenshotInfoViewModel, ScreenshotInfoViewModel_get_empty, ScreenshotDetailViewModel } from "../../../Optiscan.Models/View/ScreenshotInfoViewModel.js";
import { update, init } from "../../ScreenshotModal/ScreenshotModalState.js";
import { utcNow } from "../../../fable_modules/fable-library.3.7.12/DateOffset.js";
import { ScreenshotModalModel, InitArgs } from "../../ScreenshotModal/ScreenshotModalTypes.js";
import { newGuid } from "../../../fable_modules/fable-library.3.7.12/Guid.js";
import { S3_PutObjectRequestParams, S3_PutObjectRequestParams_get_create } from "../../../bindings/Aws/Fable.Helpers.Aws.js";
import { Toastr_clearAll } from "../../../fable_modules/Elmish.Toastr.2.1.0/Elmish.Toastr.fs.js";

export function playbackTick(viewportIndex) {
    return singleton.Delay(() => singleton.Bind(sleep(1000), () => singleton.Return(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(46, viewportIndex)))));
}

function tryGetFileMetadataForSessionElseEmpty(model, sessionId) {
    return defaultArg(tryFind(sessionId, model.sessionsFileMetadata), FileMetadata_get_empty());
}

function tryGetFileMetadataForSessionsElseEmpty(model, sessions) {
    return toList(delay(() => collect((s) => (FSharpMap__ContainsKey(model.sessionsFileMetadata, s.sessionId) ? singleton_1(FSharpMap__get_Item(model.sessionsFileMetadata, s.sessionId)) : empty()), sessions)));
}

function getFrameInformationString(model, viewportIndex) {
    const viewport = item_3(viewportIndex, model.viewports);
    const location = viewport.selectedSequence.locationIndex | 0;
    const site = viewport.selectedSequence.siteIndex | 0;
    const locationName = item_3(location, tryGetFileMetadataForSessionElseEmpty(model, viewport.selectedSequence.sessionId).locationNames);
    const siteName = item_3(site, item_3(location, tryGetFileMetadataForSessionElseEmpty(model, viewport.selectedSequence.sessionId).siteNames));
    const sequenceName = item_3(viewport.selectedSequence.sequenceIndex, item_3(site, item_3(location, tryGetFileMetadataForSessionElseEmpty(model, viewport.selectedSequence.sessionId).sequenceNames)));
    return `Viewport ${(viewportIndex + 1)}:
	${locationName}
	${siteName}
	${sequenceName}
	Frame ${viewport.frameIndex}`;
}

function getViewportName(model, viewportIndex) {
    const location = item_3(viewportIndex, model.viewports).selectedSequence.locationIndex | 0;
    const site = item_3(viewportIndex, model.viewports).selectedSequence.siteIndex | 0;
    const sequence = item_3(viewportIndex, model.viewports).selectedSequence.sequenceIndex | 0;
    const sessionId = item_3(viewportIndex, model.viewports).selectedSequence.sessionId;
    const locationName = item_3(location, FSharpMap__get_Item(model.sessionsFileMetadata, sessionId).locationNames);
    const siteName = item_3(site, item_3(location, FSharpMap__get_Item(model.sessionsFileMetadata, sessionId).siteNames));
    const sequenceName = item_3(sequence, item_3(site, item_3(location, FSharpMap__get_Item(model.sessionsFileMetadata, sessionId).sequenceNames)));
    return `${locationName} | ${siteName} | ${sequenceName}`;
}

export function emptyViewport(selectedSessionId) {
    return new HistoricalViewport(new SelectedSequence(selectedSessionId, 0, 0, 0), 0, new LoadingState(0), [], empty_1(), true, false);
}

export function clampFrameIndex(model, selectedSequence, frameIndex) {
    return min(comparePrimitives, length(item_3(selectedSequence.sequenceIndex, item_3(selectedSequence.siteIndex, item_3(selectedSequence.locationIndex, FSharpMap__get_Item(model.sessionsFileMetadata, selectedSequence.sessionId).frames)))) - 1, max(comparePrimitives, 0, frameIndex));
}

export function patientHistoricalComparisonView(currentUser) {
    return new StateTreeNode$6((deps) => {
        Dicoms_clearDicomCache();
        const initialSortInfo = (lastColumnClicked) => (new Sort_SortInfo$1(lastColumnClicked, new Sort_SortDirection(0)));
        const selectedSessionId = defaultArg(map((s) => s.sessionId, deps.maybeSelectedSession), "00000000-0000-0000-0000-000000000000");
        return [new PatientHistoricalComparisonModel(deps.patient, selectedSessionId, defaultArg(map((s_1) => s_1.sessionName, deps.maybeSelectedSession), "--"), sortBy((s_2) => s_2.dateCreated, filter((session) => (session.sessionState === 2), deps.sessions), {
            Compare: compare,
        }), empty_2(), singleton_2(emptyViewport(selectedSessionId)), void 0, new ComparisonLayout(0), initialSortInfo(item_3(0, flaggedImagesTableColumns((value_2) => {
        }))), initialSortInfo(item_3(0, sessionAnnotationsTableColumns((value_3) => {
        }))), empty_2(), empty_2(), true, false, false, void 0, void 0, new SessionAnnotationNoteState(2), defaultArg(map(singleton_2, deps.maybeSelectedSession), empty_1()), false, void 0), Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(1))), Cmd_OfFunc_result(new StateTreeMsg$4(3, new PatientsOutboundMsg(0, true)))]))];
    }, (model, dispatch) => columns(singleton_2(new Option(8, model.loadingScreenshots ? "is-loading" : "")), toList(delay(() => {
        let props_2, css, children_2;
        return append(singleton_1((props_2 = [new HTMLAttr(64, "modal"), (css = ofArray([new CSSProp(125, model.selectingViewport ? "initial" : "none"), new CSSProp(291, "fixed")]), ["style", keyValueList(css, 1)]), new DOMAttr(40, (_arg1) => {
            dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(50)));
        })], (children_2 = [react.createElement("div", {
            className: "modal-background",
        })], react.createElement("div", keyValueList(props_2, 1), ...children_2)))), delay(() => {
            let matchValue, annotation_1;
            return append((matchValue = [model.sessionAnnotationNoteState, model.selectedAnnotation], (matchValue[0].tag === 0) ? ((matchValue[1] != null) ? singleton_1(sessionAnnotationsEditModal(matchValue[1], model.savingAnnotations ? true : model.loadingAnnotations, (arg_1) => {
                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(25, arg_1)));
            }, (_arg2) => {
                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(27)));
            }, (_arg3) => {
                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(26, new SessionAnnotationNoteState(2))));
            })) : singleton_1(Helpers_nothing)) : ((matchValue[0].tag === 1) ? ((matchValue[1] != null) ? ((annotation_1 = matchValue[1], singleton_1(sessionAnnotationDeleteModal((_arg4) => {
                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(19, annotation_1)));
            }, (_arg5) => {
                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(26, new SessionAnnotationNoteState(2))));
            })))) : singleton_1(Helpers_nothing)) : singleton_1(Helpers_nothing))), delay(() => {
                let matchValue_1;
                return append((matchValue_1 = model.maybeScreenshotModalModel, (matchValue_1 == null) ? singleton_1(Helpers_nothing) : singleton_1(screenshotModal(matchValue_1, (arg_3) => {
                    dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(51, arg_3)));
                }, (_arg6) => {
                    dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(31)));
                }, (_arg7) => {
                    dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(30)));
                }))), delay(() => append(singleton_1(column(ofArray([new Option_1(2, "session-details-column full-page-content"), new Option_1(4, singleton_2(new Modifier_IModifier(24, new Screen(0), !(model.maybeMaximizedViewportIndex == null)))), new Option_1(0, new Screen(0), new ISize(7))]), singleton_2(card(ofArray([new Common_GenericOption(0, "embed-card"), new Common_GenericOption(1, singleton_2(["style", {
                    height: "100%",
                }]))]), ofArray([header(empty_1(), singleton_2(Header_title(empty_1(), singleton_2("Flagged Images and Annotations")))), content(empty_1(), ofArray([FormField_ReactSelect_multi(model.availableSessions, [], (s_4) => s_4.sessionName, model.sidetableSessionFilter, new FormField_ReactSelect_CallbackMulti$1(0, (arg_5) => {
                    dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(48, arg_5)));
                }), "Session Filter", "Historical", [], true), flaggedImagesTableContents(tryGetFileMetadataForSessionsElseEmpty(model, model.sidetableSessionFilter), model.flaggedImagesSortInfo, (item) => {
                    dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(49, item)));
                }, (sortInfo, _arg8) => {
                    dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(41, sortInfo)));
                }), sessionAnnotationsTableCard(toList(delay(() => collect((s_5) => (FSharpMap__ContainsKey(model.sessionAnnotationsForSidebar, s_5.sessionId) ? FSharpMap__get_Item(model.sessionAnnotationsForSidebar, s_5.sessionId) : empty()), model.sidetableSessionFilter))), model.selectedAnnotation, model.sessionAnnotationsSortInfo, (_arg9) => {
                    dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(26, new SessionAnnotationNoteState(0))));
                }, (_arg10) => {
                    dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(26, new SessionAnnotationNoteState(1))));
                }, (item_1, _arg11) => {
                    dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(38, item_1)));
                }, (sortInfo_1, _arg12) => {
                    dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(42, sortInfo_1)));
                }, (annotation_2) => {
                    dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(39, annotation_2)));
                })]))]))))), delay(() => {
                    let children_36;
                    return singleton_1(column(singleton_2(new Option_1(2, "full-page-content historical-column")), ofArray([navbar(ofArray([new Option_2(7, "columns tele-nav"), new Option_2(8, singleton_2(new Modifier_IModifier(24, new Screen(0), !(model.maybeMaximizedViewportIndex == null))))]), ofArray([Start_div(empty_1(), ofArray([Item_div(singleton_2(new Item_Option(6, "tele-nav-back-button no-margin")), singleton_2(button(singleton_2(new Option_3(18, (_arg13) => {
                        Dicoms_clearDicomCache();
                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(13)));
                    })), singleton_2("Back")))), Item_div(empty_1(), singleton_2(button(ofArray([new Option_3(18, (_arg14) => {
                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(35)));
                    }), new Option_3(0, new Color_IColor(6))]), singleton_2("Patient Reports"))))])), End_div(ofArray([new Common_GenericOption(0, "column is-2"), new Common_GenericOption(1, singleton_2(["style", {
                        padding: 0,
                    }]))]), singleton_2(Item_div(empty_1(), singleton_2(FormField_ReactSelect_single([new ComparisonLayout(0), new ComparisonLayout(1, 1), new ComparisonLayout(1, 2), new ComparisonLayout(2, 1), new ComparisonLayout(2, 2)], [], (_arg1_1) => ((_arg1_1.tag === 1) ? (`2x${_arg1_1.fields[0]}`) : ((_arg1_1.tag === 2) ? (`3x${_arg1_1.fields[0]}`) : "Single")), model.layout, new FormField_ReactSelect_CallbackSingle$1(0, (arg_8) => {
                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(14, defaultArg(arg_8, model.layout))));
                    }), "Layout", false, "Historical", [new Option_4(11, singleton_2(["style", {
                        flexGrow: 1,
                    }]))])))))])), (children_36 = toList(delay(() => append(mapIndexed((viewportIdx, viewport) => {
                        let props_14, children_12, children_26;
                        const matchValue_2 = viewport.loadingState;
                        switch (matchValue_2.tag) {
                            case 0:
                            case 1:
                            case 3: {
                                let loadingClass;
                                const matchValue_3 = viewport.loadingState;
                                switch (matchValue_3.tag) {
                                    case 0:
                                    case 1: {
                                        loadingClass = "is-loading";
                                        break;
                                    }
                                    default: {
                                        loadingClass = "";
                                    }
                                }
                                let layoutClasses;
                                const matchValue_4 = model.layout;
                                switch (matchValue_4.tag) {
                                    case 1: {
                                        layoutClasses = "row-double";
                                        break;
                                    }
                                    case 2: {
                                        layoutClasses = "row-triple";
                                        break;
                                    }
                                    default: {
                                        layoutClasses = "row-single";
                                    }
                                }
                                let layoutHeightClasses;
                                const matchValue_5 = model.layout;
                                let pattern_matching_result;
                                if (matchValue_5.tag === 1) {
                                    if (matchValue_5.fields[0] === 1) {
                                        pattern_matching_result = 0;
                                    }
                                    else {
                                        pattern_matching_result = 1;
                                    }
                                }
                                else if (matchValue_5.tag === 2) {
                                    if (matchValue_5.fields[0] === 1) {
                                        pattern_matching_result = 0;
                                    }
                                    else {
                                        pattern_matching_result = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result = 0;
                                }
                                switch (pattern_matching_result) {
                                    case 0: {
                                        layoutHeightClasses = "tall";
                                        break;
                                    }
                                    case 1: {
                                        layoutHeightClasses = "";
                                        break;
                                    }
                                }
                                let selectedClass;
                                const matchValue_6 = model.maybeMaximizedViewportIndex;
                                selectedClass = ((matchValue_6 == null) ? "" : ((viewportIdx === matchValue_6) ? "historical-container--selected" : "historical-container--hidden"));
                                const props_30 = [new HTMLAttr(64, `historical-container ${loadingClass} ${layoutClasses} ${layoutHeightClasses} ${selectedClass}`)];
                                const children_28 = [(props_14 = [["style", {
                                    width: "100%",
                                    flex: "1",
                                    display: "flex",
                                }]], (children_12 = toList(delay(() => {
                                    let props_12, children_10, testExpr;
                                    const matchValue_7 = [viewport.loadedDicomFiles, viewport.isLoadingAnnotations];
                                    let pattern_matching_result_1;
                                    if ((testExpr = matchValue_7[0], (!equalsWith(equals, testExpr, null)) && (testExpr.length === 0))) {
                                        pattern_matching_result_1 = 0;
                                    }
                                    else if (matchValue_7[1]) {
                                        pattern_matching_result_1 = 0;
                                    }
                                    else {
                                        pattern_matching_result_1 = 1;
                                    }
                                    switch (pattern_matching_result_1) {
                                        case 0: {
                                            return singleton_1(Helpers_nothing);
                                        }
                                        case 1: {
                                            const saveSpinnerHidden = (!model.savingAnnotations) ? "is-hidden" : "";
                                            const viewportContainerId = `historical-viewport-${viewportIdx}`;
                                            return singleton_1((props_12 = [new HTMLAttr(64, "historical-view-container"), new HTMLAttr(99, viewportContainerId), ["style", {
                                                display: "flex",
                                            }]], (children_10 = toList(delay(() => {
                                                const thumbnailFrameFiles = (viewport.loadingState.tag === 3) ? toArray(defaultArg(bind((list_4) => tryItem(viewport.selectedSequence.sequenceIndex, list_4), bind((list_3) => tryItem(viewport.selectedSequence.siteIndex, list_3), tryItem(viewport.selectedSequence.locationIndex, tryGetFileMetadataForSessionElseEmpty(model, viewport.selectedSequence.sessionId).frames))), singleton_2(defaultFrameFile))) : [defaultFrameFile];
                                                return append(singleton_1(button(ofArray([new Option_3(19, "historical-container__viewport-select-button"), new Option_3(18, (_arg15) => {
                                                    const matchValue_9 = [model.selectedFlaggedImage, model.selectedAnnotation];
                                                    if (matchValue_9[0] != null) {
                                                        const _flaggedImage = matchValue_9[0];
                                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(36, viewportIdx)));
                                                    }
                                                    else if (matchValue_9[1] != null) {
                                                        const _annotation = matchValue_9[1];
                                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(37, viewportIdx)));
                                                    }
                                                    else {
                                                        console.error(some("Unable to send image(s) to viewport"));
                                                    }
                                                }), new Option_3(20, singleton_2(new Modifier_IModifier(24, new Screen(0), !model.selectingViewport)))]), singleton_2(`${(viewportIdx + 1)}`))), delay(() => {
                                                    let props_4, props_5;
                                                    return append(singleton_1((props_4 = ofArray([new ThumbnailSliderProps(0, thumbnailFrameFiles), new ThumbnailSliderProps(1, toArray(viewport.thumbnailUrls)), new ThumbnailSliderProps(2, viewport.frameIndex), new ThumbnailSliderProps(3, (i_2) => {
                                                        iterate(dispatch, ofArray([new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(45, viewportIdx)), new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(11, viewportIdx, i_2))]));
                                                    }), new ThumbnailSliderProps(4, document.getElementById(viewportContainerId)), new ThumbnailSliderProps(5, equals(model.layout, new ComparisonLayout(0)) ? true : (model.maybeMaximizedViewportIndex != null))]), (props_5 = keyValueList(props_4, 1), react.createElement(ThumbnailSlider, props_5)))), delay(() => {
                                                        let props_6;
                                                        return append(singleton_1((props_6 = [new HTMLAttr(64, `save-spinner ${saveSpinnerHidden}`)], react.createElement("div", keyValueList(props_6, 1), "Saving..."))), delay(() => append(singleton_1(button(ofArray([new Option_3(19, "historical-container__close-button"), new Option_3(20, singleton_2(new Modifier_IModifier(24, new Screen(0), model.maybeMaximizedViewportIndex == null))), new Option_3(18, (_arg16) => {
                                                            dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(47, void 0)));
                                                        })]), singleton_2(Fa_i(ofArray([new Fa_IconOption(11, "fas fa-times"), new Fa_IconOption(0, new Fa_ISize(3)), new Fa_IconOption(15, "historical-container__close-button-icon")]), [])))), delay(() => {
                                                            let props_10, children_8, props_8, props_9;
                                                            return singleton_1((props_10 = [new DOMAttr(64, (evt) => {
                                                                let tupledArg;
                                                                dispatch(new StateTreeMsg$4(0, (tupledArg = [viewportIdx, evt.deltaY], new PatientHistoricalComparisonMsg(12, tupledArg[0], tupledArg[1]))));
                                                            }), ["style", {
                                                                display: "flex",
                                                                flex: "1 1 0",
                                                            }]], (children_8 = [(props_8 = ofArray([new IHistoricalViewProps(0, viewportIdx), new IHistoricalViewProps(1, length(model.viewports)), new IHistoricalViewProps(2, viewport.frameIndex), new IHistoricalViewProps(3, matchValue_7[0]), new IHistoricalViewProps(4, toArray(concat(map_1((viewport_1) => {
                                                                const s_10 = viewport_1.selectedSequence;
                                                                return map_1((frame) => frame.filePath, item_3(s_10.sequenceIndex, item_3(s_10.siteIndex, item_3(s_10.locationIndex, FSharpMap__get_Item(model.sessionsFileMetadata, s_10.sessionId).frames))));
                                                            }, model.viewports)))), new IHistoricalViewProps(5, () => {
                                                            }), new IHistoricalViewProps(6, () => {
                                                                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(44, viewportIdx)));
                                                            }), new IHistoricalViewProps(7, () => {
                                                                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(45, viewportIdx)));
                                                            }), new IHistoricalViewProps(8, (maybeViewportIndex) => {
                                                                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(47, maybeViewportIndex)));
                                                            }), new IHistoricalViewProps(9, eventNames), new IHistoricalViewProps(10, () => {
                                                                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(43, viewportIdx)));
                                                            }), new IHistoricalViewProps(11, (a) => {
                                                                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(15, viewportIdx, a)));
                                                            }), new IHistoricalViewProps(12, (a_1) => {
                                                                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(17, viewportIdx, a_1)));
                                                            }), new IHistoricalViewProps(17, toArray(defaultArg(map((list_12) => choose((x_2) => {
                                                                if (((x_2.sequenceIndex === viewport.selectedSequence.sequenceIndex) && (x_2.siteIndex === viewport.selectedSequence.siteIndex)) && (x_2.locationIndex === viewport.selectedSequence.locationIndex)) {
                                                                    return x_2.annotationData;
                                                                }
                                                                else {
                                                                    return void 0;
                                                                }
                                                            }, list_12), tryFind(viewport.selectedSequence.sessionId, model.sessionAnnotationsForViewer)), empty_1()))), new IHistoricalViewProps(15, (a_2) => {
                                                                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(28, ofSeq(a_2))));
                                                            }), new IHistoricalViewProps(14, (a_3) => {
                                                                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(40, a_3)));
                                                            }), new IHistoricalViewProps(16, (isLoading) => {
                                                                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(29, isLoading)));
                                                            }), new IHistoricalViewProps(18, equals(model.layout, new ComparisonLayout(0)) ? true : (model.maybeMaximizedViewportIndex != null)), new IHistoricalViewProps(19, model.maybeMaximizedViewportIndex != null)]), (props_9 = keyValueList(props_8, 1), react.createElement(HistoricalView, props_9)))], react.createElement("div", keyValueList(props_10, 1), ...children_8))));
                                                        }))));
                                                    }));
                                                }));
                                            })), react.createElement("div", keyValueList(props_12, 1), ...children_10))));
                                        }
                                    }
                                })), react.createElement("div", keyValueList(props_14, 1), ...children_12))), (children_26 = toList(delay(() => {
                                    let children_24, children_22, children_16, children_18, children_20;
                                    const sessionName_1 = defaultArg(map((s_12) => s_12.sessionName, tryFind_1((s_11) => (s_11.sessionId === viewport.selectedSequence.sessionId), model.availableSessions)), "--");
                                    return singleton_1((children_24 = [FormField_ReactMenu_menu(ofArray([new MenuProps(0, "end"), new MenuProps(1, "bottom"), new MenuProps(2, 8)]), singleton_2((children_22 = [react.createElement("div", {
                                        className: "sequence-menu__label__item",
                                    }, sessionName_1), (children_16 = [item_3(viewport.selectedSequence.locationIndex, tryGetFileMetadataForSessionElseEmpty(model, viewport.selectedSequence.sessionId).locationNames)], react.createElement("div", {
                                        className: "sequence-menu__label__item",
                                    }, ...children_16)), (children_18 = [item_3(viewport.selectedSequence.siteIndex, item_3(viewport.selectedSequence.locationIndex, tryGetFileMetadataForSessionElseEmpty(model, viewport.selectedSequence.sessionId).siteNames))], react.createElement("div", {
                                        className: "sequence-menu__label__item",
                                    }, ...children_18)), (children_20 = [item_3(viewport.selectedSequence.sequenceIndex, item_3(viewport.selectedSequence.siteIndex, item_3(viewport.selectedSequence.locationIndex, tryGetFileMetadataForSessionElseEmpty(model, viewport.selectedSequence.sessionId).sequenceNames)))], react.createElement("div", {
                                        className: "sequence-menu__label__item",
                                    }, ...children_20))], react.createElement("div", {
                                        className: "sequence-menu__label",
                                    }, ...children_22))), singleton_2(toArray(map_1((tupledArg_1) => {
                                        const sessionId = tupledArg_1[0];
                                        const fileMetadata = tupledArg_1[1];
                                        const sessionName_2 = defaultArg(map((s_18) => s_18.sessionName, tryFind_1((s_17) => (s_17.sessionId === sessionId), model.availableSessions)), "--");
                                        return FormField_ReactMenu_subMenu(ofArray([new SubMenuProps(1, sessionName_2), new SubMenuProps(0, sessionName_2)]), singleton_2(toArray(mapIndexed((j, location) => FormField_ReactMenu_subMenu(ofArray([new SubMenuProps(1, location), new SubMenuProps(0, location)]), singleton_2(toArray(mapIndexed((k, site) => FormField_ReactMenu_subMenu(ofArray([new SubMenuProps(1, site), new SubMenuProps(0, site)]), singleton_2(toArray(mapIndexed((l, sequence) => FormField_ReactMenu_menuItem(ofArray([new MenuItemProps(0, sequence), new MenuItemProps(1, () => {
                                            dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(10, viewportIdx, new SelectedSequence(sessionId, j, k, l), 0)));
                                        })]), singleton_2(sequence)), item_3(k, item_3(j, fileMetadata.sequenceNames)))))), item_3(j, fileMetadata.siteNames))))), fileMetadata.locationNames))));
                                    }, toList_1(model.sessionsFileMetadata)))))], react.createElement("div", {
                                        className: "react-menu__button__container",
                                    }, ...children_24)));
                                })), react.createElement("div", {
                                    className: "multi-historical-footer",
                                }, ...children_26))];
                                return react.createElement("div", keyValueList(props_30, 1), ...children_28);
                            }
                            case 4: {
                                const children_32 = [react.createElement("h4", {
                                    className: "title",
                                }, "Failed to load DICOM files")];
                                return react.createElement("div", {
                                    className: "historical-container",
                                }, ...children_32);
                            }
                            default: {
                                return Helpers_nothing;
                            }
                        }
                    }, model.viewports), delay(() => {
                        let props_36;
                        return singleton_1((props_36 = [["style", {
                            height: "5rem",
                        }]], react.createElement("div", keyValueList(props_36, 1))));
                    })))), react.createElement("div", {
                        className: "historical-grid",
                    }, ...children_36))])));
                }))));
            }));
        }));
    }))), uncurry(4, (msg_4) => ((tupledArg_2) => {
        const token = tupledArg_2[0];
        const maybeS3Ctx = tupledArg_2[3];
        return (deps_1) => ((model_1) => {
            let inputRecord, inputRecord_1, tupledArg_5, matchValue_10, matchValue_12, f1_32, f1_31, matchValue_14, annotation_7, matchValue_16, image, matchValue_17, annotation_9, inputRecord_2, inputRecord_3, matchValue_19, inputRecord_4, inputRecord_5, inputRecord_6, tupledArg_6, inputRecord_7, ctx;
            let pattern_matching_result_2, e, parsedFileNamesList, err, sessionId_2, meta, err_1, selectedSequence_1, fileArray, filePathsArray, selectedSequence_2, err_2, selectedSequence_3, selectedSequence_4, i_4, selectedSequence_5, url_1, filePathsArray_1, frameIndex, selectedSequence_6, viewportIndex_1, frameIndex_1, viewportIndex_2, delta, viewportIndex_3, layout_1, annotation_3, viewportIndex_4, annotation_4, viewportIndex_5, annotation_5, annotation_6, deletedAnnotationUID, maybeViewportIndex_1, sessionId_4, sessionId_5, annotations_2, annotations_4, maybeViewportIndex_2, err_3, state_2, setter, viewportIndex_6, viewportIndex_7, annotation_10, annotation_11, annotationUID_1, sortInfo_2, sortInfo_3, viewportIndex_8, viewportIndex_9, viewportIndex_10, viewportIndex_11, canvasInfo, screenshotId_1, sessionScreenshotDetails_3, err_4, isLoading_1, maybeViewportIndex_3, sessions, item_2, msg_64;
            if (msg_4.tag === 1) {
                pattern_matching_result_2 = 1;
            }
            else if (msg_4.tag === 2) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_2 = 3;
                    err = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 2;
                    parsedFileNamesList = msg_4.fields[0].fields[0];
                }
            }
            else if (msg_4.tag === 3) {
                pattern_matching_result_2 = 4;
                sessionId_2 = msg_4.fields[0];
            }
            else if (msg_4.tag === 4) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_2 = 6;
                    err_1 = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 5;
                    meta = msg_4.fields[0].fields[0];
                }
            }
            else if (msg_4.tag === 5) {
                pattern_matching_result_2 = 7;
                selectedSequence_1 = msg_4.fields[0];
            }
            else if (msg_4.tag === 6) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_2 = 9;
                    err_2 = msg_4.fields[0].fields[0];
                    selectedSequence_3 = msg_4.fields[1];
                }
                else {
                    pattern_matching_result_2 = 8;
                    fileArray = msg_4.fields[0].fields[0][0];
                    filePathsArray = msg_4.fields[0].fields[0][1];
                    selectedSequence_2 = msg_4.fields[1];
                }
            }
            else if (msg_4.tag === 7) {
                pattern_matching_result_2 = 10;
                selectedSequence_4 = msg_4.fields[0];
            }
            else if (msg_4.tag === 8) {
                pattern_matching_result_2 = 11;
                i_4 = msg_4.fields[0];
                selectedSequence_5 = msg_4.fields[2];
                url_1 = msg_4.fields[1];
            }
            else if (msg_4.tag === 9) {
                pattern_matching_result_2 = 12;
                filePathsArray_1 = msg_4.fields[0];
            }
            else if (msg_4.tag === 10) {
                pattern_matching_result_2 = 13;
                frameIndex = msg_4.fields[2];
                selectedSequence_6 = msg_4.fields[1];
                viewportIndex_1 = msg_4.fields[0];
            }
            else if (msg_4.tag === 11) {
                pattern_matching_result_2 = 14;
                frameIndex_1 = msg_4.fields[1];
                viewportIndex_2 = msg_4.fields[0];
            }
            else if (msg_4.tag === 12) {
                pattern_matching_result_2 = 15;
                delta = msg_4.fields[1];
                viewportIndex_3 = msg_4.fields[0];
            }
            else if (msg_4.tag === 13) {
                pattern_matching_result_2 = 16;
            }
            else if (msg_4.tag === 14) {
                pattern_matching_result_2 = 17;
                layout_1 = msg_4.fields[0];
            }
            else if (msg_4.tag === 15) {
                pattern_matching_result_2 = 18;
                annotation_3 = msg_4.fields[1];
                viewportIndex_4 = msg_4.fields[0];
            }
            else if (msg_4.tag === 16) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_2 = 28;
                    err_3 = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 19;
                }
            }
            else if (msg_4.tag === 17) {
                pattern_matching_result_2 = 20;
                annotation_4 = msg_4.fields[1];
                viewportIndex_5 = msg_4.fields[0];
            }
            else if (msg_4.tag === 18) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_2 = 28;
                    err_3 = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 21;
                    annotation_5 = msg_4.fields[0].fields[0];
                }
            }
            else if (msg_4.tag === 19) {
                pattern_matching_result_2 = 22;
                annotation_6 = msg_4.fields[0];
            }
            else if (msg_4.tag === 20) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_2 = 28;
                    err_3 = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 23;
                    deletedAnnotationUID = msg_4.fields[0].fields[0];
                }
            }
            else if (msg_4.tag === 23) {
                pattern_matching_result_2 = 24;
                maybeViewportIndex_1 = msg_4.fields[0];
                sessionId_4 = msg_4.fields[1];
            }
            else if (msg_4.tag === 21) {
                pattern_matching_result_2 = 25;
                sessionId_5 = msg_4.fields[0];
            }
            else if (msg_4.tag === 22) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_2 = 28;
                    err_3 = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 26;
                    annotations_2 = msg_4.fields[0].fields[0];
                }
            }
            else if (msg_4.tag === 24) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_2 = 28;
                    err_3 = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 27;
                    annotations_4 = msg_4.fields[0].fields[0][1];
                    maybeViewportIndex_2 = msg_4.fields[0].fields[0][0];
                }
            }
            else if (msg_4.tag === 26) {
                pattern_matching_result_2 = 29;
                state_2 = msg_4.fields[0];
            }
            else if (msg_4.tag === 25) {
                pattern_matching_result_2 = 30;
                setter = msg_4.fields[0];
            }
            else if (msg_4.tag === 27) {
                pattern_matching_result_2 = 31;
            }
            else if (msg_4.tag === 35) {
                pattern_matching_result_2 = 32;
            }
            else if (msg_4.tag === 36) {
                pattern_matching_result_2 = 33;
                viewportIndex_6 = msg_4.fields[0];
            }
            else if (msg_4.tag === 37) {
                pattern_matching_result_2 = 34;
                viewportIndex_7 = msg_4.fields[0];
            }
            else if (msg_4.tag === 38) {
                pattern_matching_result_2 = 35;
                annotation_10 = msg_4.fields[0];
            }
            else if (msg_4.tag === 39) {
                pattern_matching_result_2 = 36;
                annotation_11 = msg_4.fields[0];
            }
            else if (msg_4.tag === 40) {
                pattern_matching_result_2 = 37;
                annotationUID_1 = msg_4.fields[0];
            }
            else if (msg_4.tag === 41) {
                pattern_matching_result_2 = 38;
                sortInfo_2 = msg_4.fields[0];
            }
            else if (msg_4.tag === 42) {
                pattern_matching_result_2 = 39;
                sortInfo_3 = msg_4.fields[0];
            }
            else if (msg_4.tag === 43) {
                pattern_matching_result_2 = 40;
                viewportIndex_8 = msg_4.fields[0];
            }
            else if (msg_4.tag === 44) {
                pattern_matching_result_2 = 41;
                viewportIndex_9 = msg_4.fields[0];
            }
            else if (msg_4.tag === 45) {
                pattern_matching_result_2 = 42;
                viewportIndex_10 = msg_4.fields[0];
            }
            else if (msg_4.tag === 46) {
                pattern_matching_result_2 = 43;
                viewportIndex_11 = msg_4.fields[0];
            }
            else if (msg_4.tag === 28) {
                pattern_matching_result_2 = 44;
                canvasInfo = msg_4.fields[0];
            }
            else if (msg_4.tag === 31) {
                pattern_matching_result_2 = 45;
            }
            else if (msg_4.tag === 32) {
                pattern_matching_result_2 = 46;
                screenshotId_1 = msg_4.fields[0];
                sessionScreenshotDetails_3 = msg_4.fields[1];
            }
            else if (msg_4.tag === 33) {
                pattern_matching_result_2 = 47;
            }
            else if (msg_4.tag === 34) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_2 = 49;
                    err_4 = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 48;
                }
            }
            else if (msg_4.tag === 29) {
                pattern_matching_result_2 = 50;
                isLoading_1 = msg_4.fields[0];
            }
            else if (msg_4.tag === 30) {
                pattern_matching_result_2 = 51;
            }
            else if (msg_4.tag === 47) {
                pattern_matching_result_2 = 52;
                maybeViewportIndex_3 = msg_4.fields[0];
            }
            else if (msg_4.tag === 48) {
                pattern_matching_result_2 = 53;
                sessions = msg_4.fields[0];
            }
            else if (msg_4.tag === 49) {
                pattern_matching_result_2 = 54;
                item_2 = msg_4.fields[0];
            }
            else if (msg_4.tag === 50) {
                pattern_matching_result_2 = 55;
            }
            else if (msg_4.tag === 51) {
                pattern_matching_result_2 = 56;
                msg_64 = msg_4.fields[0];
            }
            else {
                pattern_matching_result_2 = 0;
                e = msg_4.fields[0];
            }
            switch (pattern_matching_result_2) {
                case 0: {
                    console.error(some(`Error: ${e}`));
                    return [model_1, Cmd_none()];
                }
                case 1: {
                    if (maybeS3Ctx == null) {
                        return [model_1, Cmd_none()];
                    }
                    else {
                        const s3Ctx = maybeS3Ctx;
                        return [model_1, Cmd_OfPromise_either(() => {
                            let pr_1;
                            const pr = map_1((session_1) => Dicoms_loadAndParseFileList(model_1.patient.id, session_1.sessionId, s3Ctx), model_1.availableSessions);
                            pr_1 = (Promise.all(pr));
                            return pr_1.then(ofArray);
                        }, void 0, (arg_10) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(2, new FSharpResult$2(0, arg_10)))), (arg_12) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(2, new FSharpResult$2(1, arg_12)))))];
                    }
                }
                case 2: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, ofList(map_1((x_3) => [x_3.sessionId, x_3], parsedFileNamesList)), map_1((viewport_2) => (new HistoricalViewport(viewport_2.selectedSequence, viewport_2.frameIndex, new LoadingState(1), viewport_2.loadedDicomFiles, viewport_2.thumbnailUrls, viewport_2.isPlaybackPaused, viewport_2.isLoadingAnnotations)), model_1.viewports), model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_batch(toList(delay(() => append(map_1((selectedSequence) => Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(5, selectedSequence))), List_distinct(map_1((viewport_3) => viewport_3.selectedSequence, model_1.viewports), {
                        Equals: equals,
                        GetHashCode: safeHash,
                    })), delay(() => concat(map_1((sessionId_1) => ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(3, sessionId_1))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(21, sessionId_1)))]), List_distinct(map_1((session_2) => session_2.sessionId, model_1.availableSessions), {
                        Equals: (x_5, y_3) => (x_5 === y_3),
                        GetHashCode: stringHash,
                    }))))))))];
                }
                case 3: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, map_1((viewport_4) => (new HistoricalViewport(viewport_4.selectedSequence, viewport_4.frameIndex, new LoadingState(4), viewport_4.loadedDicomFiles, viewport_4.thumbnailUrls, viewport_4.isPlaybackPaused, viewport_4.isLoadingAnnotations)), model_1.viewports), model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, err)))];
                }
                case 4: {
                    return [model_1, Cmd_OfAsyncWith_either((x_6) => {
                        Cmd_OfAsync_start(x_6);
                    }, (tupledArg_3) => FlaggedImages_loadFlaggedImagesPromise(tupledArg_3[0], tupledArg_3[1], tupledArg_3[2]), [sessionId_2, tryGetFileMetadataForSessionElseEmpty(model_1, sessionId_2), token], (arg_13) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(4, arg_13))), (arg_16) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(4, new FSharpResult$2(1, new ErrorMessage(2, arg_16))))))];
                }
                case 5: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, add(meta.sessionId, meta, model_1.sessionsFileMetadata), model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_none()];
                }
                case 6: {
                    return [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(1, err_1))];
                }
                case 7: {
                    if (maybeS3Ctx != null) {
                        const s3Ctx_1 = maybeS3Ctx;
                        return [model_1, Cmd_OfPromise_either(() => {
                            let pr_3;
                            const pr_2 = map_1((frame_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                const fullFilePath = `${model_1.patient.id}/${selectedSequence_1.sessionId}/${frame_1.filePath}`;
                                return Dicoms_loadDicomFromS3(s3Ctx_1, fullFilePath).then((_arg2_1) => (Promise.resolve([new File([_arg2_1], (`${frame_1.filePath}`)), fullFilePath])));
                            })), item_3(selectedSequence_1.sequenceIndex, item_3(selectedSequence_1.siteIndex, item_3(selectedSequence_1.locationIndex, FSharpMap__get_Item(model_1.sessionsFileMetadata, selectedSequence_1.sessionId).frames))));
                            pr_3 = (Promise.all(pr_2));
                            return pr_3.then(unzip);
                        }, void 0, (arg_19) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(6, new FSharpResult$2(0, arg_19), selectedSequence_1))), (arg_21) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(6, new FSharpResult$2(1, arg_21), selectedSequence_1))))];
                    }
                    else {
                        return [model_1, Cmd_none()];
                    }
                }
                case 8: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, map_1((viewport_5) => {
                        if (equals(viewport_5.selectedSequence, selectedSequence_2) && (!equals(viewport_5.loadingState, new LoadingState(3)))) {
                            return new HistoricalViewport(viewport_5.selectedSequence, viewport_5.frameIndex, new LoadingState(3), fileArray, ofArray(map_2((_arg17) => (void 0), fileArray)), viewport_5.isPlaybackPaused, true);
                        }
                        else {
                            return viewport_5;
                        }
                    }, model_1.viewports), model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, true, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_batch(toList(delay(() => append(singleton_1(Cmd_OfAsyncWith_attempt((x_7) => {
                        Cmd_OfAsync_start(x_7);
                    }, securedApi(token).telemetry, new TelemetryAction(1, new FileOperationContext(0), ofArray(filePathsArray)), (arg_22) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_22))))), delay(() => append(singleton_1(Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(7, selectedSequence_2)))), delay(() => mapIndexed((viewportIdx_1, viewport_6) => {
                        if (equals(viewport_6.selectedSequence, selectedSequence_2) && (!equals(viewport_6.loadingState, new LoadingState(3)))) {
                            return Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(23, viewportIdx_1, selectedSequence_2.sessionId)));
                        }
                        else {
                            return Cmd_none();
                        }
                    }, model_1.viewports))))))))];
                }
                case 9: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, map_1((viewport_7) => {
                        if (equals(viewport_7.selectedSequence, selectedSequence_3)) {
                            return new HistoricalViewport(viewport_7.selectedSequence, viewport_7.frameIndex, new LoadingState(4), viewport_7.loadedDicomFiles, viewport_7.thumbnailUrls, viewport_7.isPlaybackPaused, viewport_7.isLoadingAnnotations);
                        }
                        else {
                            return viewport_7;
                        }
                    }, model_1.viewports), model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, err_2)))];
                }
                case 10: {
                    if (maybeS3Ctx == null) {
                        return [model_1, Cmd_none()];
                    }
                    else {
                        const s3Ctx_2 = maybeS3Ctx;
                        return [model_1, Cmd_ofSub((dispatch_1) => {
                            let pr_5;
                            const pr_4 = map_1((tupledArg_4) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                const thumbPath = replace(`${model_1.patient.id}/${selectedSequence_4.sessionId}/${tupledArg_4[1].filePath}`, ".dcm", "-thumbnail.jpg");
                                return Thumbnails_loadThumbnailFromS3(s3Ctx_2, thumbPath).then((_arg3_1) => {
                                    iterate_1((url) => {
                                        dispatch_1(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(8, tupledArg_4[0], url, selectedSequence_4)));
                                    }, toArray_1(_arg3_1));
                                    return Promise.resolve(thumbPath);
                                });
                            })), indexed(item_3(selectedSequence_4.sequenceIndex, item_3(selectedSequence_4.siteIndex, item_3(selectedSequence_4.locationIndex, FSharpMap__get_Item(model_1.sessionsFileMetadata, selectedSequence_4.sessionId).frames)))));
                            pr_5 = (Promise.all(pr_4));
                            void (pr_5.then((arg_25) => {
                                dispatch_1(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(9, arg_25)));
                            }));
                        })];
                    }
                }
                case 11: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, map_1((viewport_8) => {
                        if (equals(viewport_8.selectedSequence, selectedSequence_5)) {
                            return new HistoricalViewport(viewport_8.selectedSequence, viewport_8.frameIndex, viewport_8.loadingState, viewport_8.loadedDicomFiles, updateAt(i_4, url_1, viewport_8.thumbnailUrls), viewport_8.isPlaybackPaused, viewport_8.isLoadingAnnotations);
                        }
                        else {
                            return viewport_8;
                        }
                    }, model_1.viewports), model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_none()];
                }
                case 12: {
                    return [model_1, Cmd_OfAsyncWith_attempt((x_8) => {
                        Cmd_OfAsync_start(x_8);
                    }, securedApi(token).telemetry, new TelemetryAction(1, new FileOperationContext(1), ofArray(filePathsArray_1)), (arg_26) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_26))))];
                }
                case 13: {
                    return (equals(selectedSequence_6, item_3(viewportIndex_1, model_1.viewports).selectedSequence) && (frameIndex === item_3(viewportIndex_1, model_1.viewports).frameIndex)) ? [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(45, viewportIndex_1)))] : [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, updateAt(viewportIndex_1, (inputRecord = item_3(viewportIndex_1, model_1.viewports), new HistoricalViewport(selectedSequence_6, clampFrameIndex(model_1, selectedSequence_6, frameIndex), new LoadingState(1), inputRecord.loadedDicomFiles, inputRecord.thumbnailUrls, inputRecord.isPlaybackPaused, inputRecord.isLoadingAnnotations)), model_1.viewports), model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(5, selectedSequence_6))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(45, viewportIndex_1)))]))];
                }
                case 14: {
                    const clampedIndex = clampFrameIndex(model_1, item_3(viewportIndex_2, model_1.viewports).selectedSequence, frameIndex_1) | 0;
                    if (clampedIndex !== item_3(viewportIndex_2, model_1.viewports).frameIndex) {
                        customEvent(eventNames.SetFrame, new SetFrameEvent(viewportIndex_2, clampedIndex));
                        return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, updateAt(viewportIndex_2, (inputRecord_1 = item_3(viewportIndex_2, model_1.viewports), new HistoricalViewport(inputRecord_1.selectedSequence, clampedIndex, inputRecord_1.loadingState, inputRecord_1.loadedDicomFiles, inputRecord_1.thumbnailUrls, inputRecord_1.isPlaybackPaused, inputRecord_1.isLoadingAnnotations)), model_1.viewports), model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_none()];
                    }
                    else {
                        return [model_1, Cmd_none()];
                    }
                }
                case 15: {
                    return [model_1, Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, (tupledArg_5 = [viewportIndex_3, item_3(viewportIndex_3, model_1.viewports).frameIndex + ((delta > 0) ? 1 : -1)], new PatientHistoricalComparisonMsg(11, tupledArg_5[0], tupledArg_5[1])))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(45, viewportIndex_3)))]))];
                }
                case 16: {
                    return [model_1, Cmd_batch(ofArray([Navigation_modifyUrl(pageHash(new Page(6, model_1.patient.id, void 0))), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(1)))]))];
                }
                case 17: {
                    const newViewports = initialize((layout_1.tag === 1) ? (2 * layout_1.fields[0]) : ((layout_1.tag === 2) ? (3 * layout_1.fields[0]) : 1), (i_5) => defaultArg(map((viewport_9) => (new HistoricalViewport(viewport_9.selectedSequence, viewport_9.frameIndex, new LoadingState(2), viewport_9.loadedDicomFiles, viewport_9.thumbnailUrls, viewport_9.isPlaybackPaused, viewport_9.isLoadingAnnotations)), tryItem(i_5, model_1.viewports)), emptyViewport(model_1.sessionId)));
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, newViewports, model_1.maybeMaximizedViewportIndex, layout_1, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_batch(toList(delay(() => append(map_1((selectedSequence_8) => Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(5, selectedSequence_8))), List_distinct(map_1((viewport_10) => viewport_10.selectedSequence, newViewports), {
                        Equals: equals,
                        GetHashCode: safeHash,
                    })), delay(() => mapIndexed((i_8, _arg18) => Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(45, i_8))), newViewports))))))];
                }
                case 18: {
                    const viewport_11 = item_3(viewportIndex_4, model_1.viewports);
                    if (fold((isPresent, k_1, v) => {
                        if ((k_1 !== viewport_11.selectedSequence.sessionId) && exists((sa) => (sa.annotationId === annotation_3.annotationUID), v)) {
                            return true;
                        }
                        else {
                            return isPresent;
                        }
                    }, false, model_1.sessionAnnotationsForViewer)) {
                        return [model_1, Cmd_none()];
                    }
                    else {
                        const vm = new SessionAnnotationViewModel(viewport_11.selectedSequence.sessionId, annotation_3.annotationUID, annotation_3.annotationData, viewport_11.selectedSequence.locationIndex, viewport_11.selectedSequence.siteIndex, viewport_11.selectedSequence.sequenceIndex, annotation_3.sliceIndex, void 0, annotation_3.toolName, annotation_3.measurements);
                        return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, add(viewport_11.selectedSequence.sessionId, append_1(defaultArg(tryFind(viewport_11.selectedSequence.sessionId, model_1.sessionAnnotationsForViewer), empty_1()), singleton_2(vm)), model_1.sessionAnnotationsForViewer), model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, true, model_1.loadingScreenshots, vm, model_1.selectedFlaggedImage, new SessionAnnotationNoteState(0), (matchValue_10 = tryFind_1((s_21) => (s_21.sessionId === viewport_11.selectedSequence.sessionId), model_1.availableSessions), (matchValue_10 == null) ? model_1.sidetableSessionFilter : List_distinctBy((s_22) => s_22.sessionId, cons(matchValue_10, model_1.sidetableSessionFilter), {
                            Equals: (x_10, y_5) => (x_10 === y_5),
                            GetHashCode: stringHash,
                        })), model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_batch(ofArray([Cmd_OfAsyncWith_either((x_11) => {
                            Cmd_OfAsync_start(x_11);
                        }, securedApi(token).upsertSessionAnnotation, vm, (arg_28) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(16, arg_28))), (arg_29) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_29)))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(45, viewportIndex_4)))]))];
                    }
                }
                case 19: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, false, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_none()];
                }
                case 20: {
                    const viewport_12 = item_3(viewportIndex_5, model_1.viewports);
                    if (fold((isPresent_1, k_2, v_1) => {
                        if ((k_2 !== viewport_12.selectedSequence.sessionId) && exists((sa_1) => (sa_1.annotationId === annotation_4.annotationUID), v_1)) {
                            return true;
                        }
                        else {
                            return isPresent_1;
                        }
                    }, false, model_1.sessionAnnotationsForSidebar)) {
                        return [model_1, Cmd_none()];
                    }
                    else {
                        let vm_1;
                        const matchValue_11 = [tryFind_1((sa_2) => (sa_2.annotationId === annotation_4.annotationUID), defaultArg(tryFind(viewport_12.selectedSequence.sessionId, model_1.sessionAnnotationsForViewer), empty_1())), model_1.selectedAnnotation];
                        let pattern_matching_result_3, a_9;
                        if (matchValue_11[0] == null) {
                            if (matchValue_11[1] != null) {
                                if (annotation_4.annotationUID === matchValue_11[1].annotationId) {
                                    pattern_matching_result_3 = 0;
                                    a_9 = matchValue_11[1];
                                }
                                else {
                                    pattern_matching_result_3 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_3 = 1;
                            }
                        }
                        else if (annotation_4.annotationUID === matchValue_11[0].annotationId) {
                            pattern_matching_result_3 = 0;
                            a_9 = matchValue_11[0];
                        }
                        else {
                            pattern_matching_result_3 = 1;
                        }
                        switch (pattern_matching_result_3) {
                            case 0: {
                                vm_1 = (new SessionAnnotationViewModel(viewport_12.selectedSequence.sessionId, annotation_4.annotationUID, annotation_4.annotationData, a_9.locationIndex, a_9.siteIndex, a_9.sequenceIndex, annotation_4.sliceIndex, map((s_23) => s_23.trim(), a_9.note), map((s_24) => s_24.trim(), a_9.annotationName), annotation_4.measurements));
                                break;
                            }
                            case 1: {
                                vm_1 = (new SessionAnnotationViewModel(viewport_12.selectedSequence.sessionId, annotation_4.annotationUID, annotation_4.annotationData, viewport_12.selectedSequence.locationIndex, viewport_12.selectedSequence.siteIndex, viewport_12.selectedSequence.sequenceIndex, annotation_4.sliceIndex, void 0, void 0, annotation_4.measurements));
                                break;
                            }
                        }
                        return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, true, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_batch(ofArray([Cmd_OfAsyncWith_either((x_12) => {
                            Cmd_OfAsync_start(x_12);
                        }, securedApi(token).upsertSessionAnnotation, vm_1, (arg_31) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(18, arg_31))), (arg_32) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_32)))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(45, viewportIndex_5)))]))];
                    }
                }
                case 21: {
                    return [(matchValue_12 = tryFind_1((sa_3) => (sa_3.annotationId === annotation_5.annotationId), defaultArg(tryFind(annotation_5.sessionId, model_1.sessionAnnotationsForViewer), empty_1())), (matchValue_12 == null) ? (new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, false, model_1.loadingScreenshots, annotation_5, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel)) : (new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, false, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel))), Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(23, void 0, annotation_5.sessionId))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(21, annotation_5.sessionId)))]))];
                }
                case 22: {
                    return [model_1, Cmd_batch(toList(delay(() => append(singleton_1(Cmd_OfAsyncWith_either((x_13) => {
                        Cmd_OfAsync_start(x_13);
                    }, securedApi(token).deleteSessionAnnotation, [annotation_6.sessionId, annotation_6.annotationId], (arg_34) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(20, arg_34))), (arg_35) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_35))))), delay(() => mapIndexed((i_9, _arg19) => Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(45, i_9))), model_1.viewports))))))];
                }
                case 23: {
                    customEvent(eventNames.RemoveAnnotation, deletedAnnotationUID);
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, map_3((_k, v_2) => filter((sa_4) => (sa_4.annotationId !== deletedAnnotationUID), v_2), model_1.sessionAnnotationsForViewer), map_3((_k_1, v_3) => filter((sa_5) => (sa_5.annotationId !== deletedAnnotationUID), v_3), model_1.sessionAnnotationsForSidebar), model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, new SessionAnnotationNoteState(2), model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_none()];
                }
                case 24: {
                    return [model_1, Cmd_OfAsyncWith_either((x_14) => {
                        Cmd_OfAsync_start(x_14);
                    }, securedApi(token).getAllSessionAnnotationsForSessionId, sessionId_4, (f1_32 = ((f1_31 = Result_map()((sas) => [maybeViewportIndex_1, sas]), (arg_37) => (new PatientHistoricalComparisonMsg(24, f1_31(arg_37))))), (arg_38) => (new StateTreeMsg$4(0, f1_32(arg_38)))), (arg_39) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_39))))];
                }
                case 25: {
                    return [model_1, Cmd_OfAsyncWith_either((x_15) => {
                        Cmd_OfAsync_start(x_15);
                    }, securedApi(token).getAllSessionAnnotationsForSessionIdWithoutAnnotationData, sessionId_5, (arg_41) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(22, arg_41))), (arg_42) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_42))))];
                }
                case 26: {
                    const matchValue_13 = model_1.selectedAnnotation;
                    if (matchValue_13 != null) {
                        const selectedAnnotation_2 = matchValue_13;
                        const node = document.getElementById(selectedAnnotation_2.annotationId);
                        if (equals(node, null)) {
                        }
                        else {
                            node.scrollIntoView();
                        }
                    }
                    if (isEmpty(annotations_2)) {
                        return [model_1, Cmd_none()];
                    }
                    else {
                        const annotations_3 = annotations_2;
                        return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, add(item_3(0, annotations_3).sessionId, annotations_3, model_1.sessionAnnotationsForSidebar), model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_none()];
                    }
                }
                case 27: {
                    const updatedViewports = mapIndexed((i_10, viewport_13) => {
                        if (equals(i_10, maybeViewportIndex_2)) {
                            return new HistoricalViewport(viewport_13.selectedSequence, viewport_13.frameIndex, viewport_13.loadingState, viewport_13.loadedDicomFiles, viewport_13.thumbnailUrls, viewport_13.isPlaybackPaused, false);
                        }
                        else {
                            return viewport_13;
                        }
                    }, model_1.viewports);
                    if (isEmpty(annotations_4)) {
                        return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, updatedViewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, false, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_none()];
                    }
                    else {
                        const annotations_5 = annotations_4;
                        return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, updatedViewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, add(item_3(0, annotations_5).sessionId, annotations_5, model_1.sessionAnnotationsForViewer), model_1.sessionAnnotationsForSidebar, false, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_none()];
                    }
                }
                case 28: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, false, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), error(ErrorMessage_get_describe()(err_3))];
                }
                case 29: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, (matchValue_14 = [model_1.selectedAnnotation, state_2], (matchValue_14[0] != null) ? ((matchValue_14[1].tag === 2) ? ((annotation_7 = matchValue_14[0], bind((list_53) => tryFind_1((a_10) => (a_10.annotationId === annotation_7.annotationId), list_53), tryFind(annotation_7.sessionId, model_1.sessionAnnotationsForSidebar)))) : model_1.selectedAnnotation) : model_1.selectedAnnotation), model_1.selectedFlaggedImage, state_2, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_none()];
                }
                case 30: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, map(setter, model_1.selectedAnnotation), model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_none()];
                }
                case 31: {
                    const matchValue_15 = model_1.selectedAnnotation;
                    if (matchValue_15 == null) {
                        return [model_1, Cmd_none()];
                    }
                    else {
                        const annotation_8 = matchValue_15;
                        customEvent(eventNames.SetAnnotationText, {
                            annotationText: defaultArg(annotation_8.note, ""),
                            annotationUID: annotation_8.annotationId,
                        });
                        const updatedViewerAnnotations = map_1((sa_6) => {
                            if (sa_6.annotationId === annotation_8.annotationId) {
                                return new SessionAnnotationViewModel(annotation_8.sessionId, annotation_8.annotationId, sa_6.annotationData, annotation_8.locationIndex, annotation_8.siteIndex, annotation_8.sequenceIndex, annotation_8.sliceIndex, annotation_8.note, annotation_8.annotationName, annotation_8.measurements);
                            }
                            else {
                                return sa_6;
                            }
                        }, defaultArg(tryFind(annotation_8.sessionId, model_1.sessionAnnotationsForViewer), empty_1()));
                        const updatedSidebarAnnotations = map_1((sa_7) => {
                            if (sa_7.annotationId === annotation_8.annotationId) {
                                return annotation_8;
                            }
                            else {
                                return sa_7;
                            }
                        }, defaultArg(tryFind(annotation_8.sessionId, model_1.sessionAnnotationsForSidebar), empty_1()));
                        const maybeUpdatedAnnotation = map((sa_9) => (new SessionAnnotationViewModel(sa_9.sessionId, sa_9.annotationId, sa_9.annotationData, sa_9.locationIndex, sa_9.siteIndex, sa_9.sequenceIndex, sa_9.sliceIndex, annotation_8.note, annotation_8.annotationName, sa_9.measurements)), bind((list_56) => tryFind_1((sa_8) => (sa_8.annotationId === annotation_8.annotationId), list_56), tryFind(annotation_8.sessionId, model_1.sessionAnnotationsForViewer)));
                        if (maybeUpdatedAnnotation == null) {
                            return [model_1, error("Failed to save annotation changes. Please try again")];
                        }
                        else {
                            const updatedAnnotation = maybeUpdatedAnnotation;
                            return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, add(annotation_8.sessionId, updatedViewerAnnotations, model_1.sessionAnnotationsForViewer), add(annotation_8.sessionId, updatedSidebarAnnotations, model_1.sessionAnnotationsForSidebar), model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, new SessionAnnotationNoteState(2), model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_batch(toList(delay(() => append(singleton_1(Cmd_OfAsyncWith_either((x_16) => {
                                Cmd_OfAsync_start(x_16);
                            }, securedApi(token).upsertSessionAnnotation, updatedAnnotation, (arg_44) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(18, arg_44))), (arg_45) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_45))))), delay(() => mapIndexed((i_11, _arg20) => Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(45, i_11))), model_1.viewports))))))];
                        }
                    }
                }
                case 32: {
                    return [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(5, "Report")))];
                }
                case 33: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, void 0, void 0, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), (matchValue_16 = model_1.selectedFlaggedImage, (matchValue_16 != null) ? ((image = matchValue_16, Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(10, viewportIndex_6, new SelectedSequence(image.sessionId, image.locationIndex, image.siteIndex, image.sequenceIndex), image.frameIndex))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(50)))])))) : Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(50))))];
                }
                case 34: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, void 0, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), (matchValue_17 = model_1.selectedAnnotation, (matchValue_17 != null) ? ((annotation_9 = matchValue_17, Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(10, viewportIndex_7, new SelectedSequence(annotation_9.sessionId, annotation_9.locationIndex, annotation_9.siteIndex, annotation_9.sequenceIndex), annotation_9.sliceIndex))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(50)))])))) : Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(50))))];
                }
                case 35: {
                    customEvent(eventNames.SelectAnnotation, annotation_10.annotationId);
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, annotation_10, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_none()];
                }
                case 36: {
                    customEvent(eventNames.SelectAnnotation, annotation_11.annotationId);
                    return equals(model_1.layout, new ComparisonLayout(0)) ? [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, annotation_11, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(37, 0)))] : [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, annotation_11, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, true, model_1.maybeScreenshotModalModel), infoPersistent("Select a viewport to display the image or click elsewhere to cancel this action")];
                }
                case 37: {
                    const maybeAnnotationID = isNullOrWhiteSpace(annotationUID_1) ? (void 0) : annotationUID_1;
                    const node_1 = document.getElementById(`${annotationUID_1}`);
                    if (equals(node_1, null)) {
                    }
                    else {
                        node_1.scrollIntoView();
                    }
                    const maybeSelectedAnnotation = tryPick((_arg21, annotations_6) => tryFind_1((sa_10) => equals(sa_10.annotationId, maybeAnnotationID), annotations_6), model_1.sessionAnnotationsForViewer);
                    return [(maybeSelectedAnnotation == null) ? model_1 : (new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, maybeSelectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel)), Cmd_batch(mapIndexed((i_12, _arg22) => Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(45, i_12))), model_1.viewports))];
                }
                case 38: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, sortInfo_2, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_none()];
                }
                case 39: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, sortInfo_3, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_none()];
                }
                case 40: {
                    const viewport_14 = item_3(viewportIndex_8, model_1.viewports);
                    const flaggedImageListItem = FlaggedImages_mkFlaggedImageListItemFromSelected(tryGetFileMetadataForSessionElseEmpty(model_1, viewport_14.selectedSequence.sessionId), viewport_14.selectedSequence, viewport_14.frameIndex);
                    const maybeFrameNumber = FlaggedImages_tryGetFrameNumber(tryGetFileMetadataForSessionElseEmpty(model_1, viewport_14.selectedSequence.sessionId), flaggedImageListItem, viewport_14.frameIndex);
                    if (maybeFrameNumber == null) {
                        return [model_1, error("Failed to toggle image flag, something went wrong")];
                    }
                    else {
                        const frameNumber = maybeFrameNumber | 0;
                        if (!item_3(viewport_14.frameIndex, item_3(viewport_14.selectedSequence.sequenceIndex, item_3(viewport_14.selectedSequence.siteIndex, item_3(viewport_14.selectedSequence.locationIndex, tryGetFileMetadataForSessionElseEmpty(model_1, viewport_14.selectedSequence.sessionId).frames)))).flagged) {
                            const updatedFrames = FlaggedImages_flagFrame(true, tryGetFileMetadataForSessionElseEmpty(model_1, viewport_14.selectedSequence.sessionId).frames, viewport_14.selectedSequence.locationIndex, viewport_14.selectedSequence.siteIndex, viewport_14.selectedSequence.sequenceIndex, viewport_14.frameIndex);
                            const updatedFlaggedImagesList = cons(flaggedImageListItem, tryGetFileMetadataForSessionElseEmpty(model_1, viewport_14.selectedSequence.sessionId).flaggedImages);
                            return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, add(viewport_14.selectedSequence.sessionId, (inputRecord_2 = tryGetFileMetadataForSessionElseEmpty(model_1, viewport_14.selectedSequence.sessionId), new FileMetadata(inputRecord_2.locationNames, inputRecord_2.siteNames, inputRecord_2.sequenceNames, updatedFrames, updatedFlaggedImagesList, inputRecord_2.frameToSequenceMap, inputRecord_2.sessionId)), model_1.sessionsFileMetadata), model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_OfAsyncWith_attempt((x_17) => {
                                Cmd_OfAsync_start(x_17);
                            }, securedApi(token).flagSessionImage, [viewport_14.selectedSequence.sessionId, frameNumber], (arg_47) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_47))))];
                        }
                        else {
                            const updatedFrames_1 = FlaggedImages_flagFrame(false, tryGetFileMetadataForSessionElseEmpty(model_1, viewport_14.selectedSequence.sessionId).frames, viewport_14.selectedSequence.locationIndex, viewport_14.selectedSequence.siteIndex, viewport_14.selectedSequence.sequenceIndex, viewport_14.frameIndex);
                            const updatedFlaggedImagesList_1 = defaultArg(map((i_13) => removeAt(i_13, tryGetFileMetadataForSessionElseEmpty(model_1, viewport_14.selectedSequence.sessionId).flaggedImages), tryFindIndex((f) => {
                                if (((f.locationIndex === viewport_14.selectedSequence.locationIndex) && (f.siteIndex === viewport_14.selectedSequence.siteIndex)) && (f.sequenceIndex === viewport_14.selectedSequence.sequenceIndex)) {
                                    return f.frameIndex === viewport_14.frameIndex;
                                }
                                else {
                                    return false;
                                }
                            }, tryGetFileMetadataForSessionElseEmpty(model_1, viewport_14.selectedSequence.sessionId).flaggedImages)), tryGetFileMetadataForSessionElseEmpty(model_1, viewport_14.selectedSequence.sessionId).flaggedImages);
                            return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, add(viewport_14.selectedSequence.sessionId, (inputRecord_3 = tryGetFileMetadataForSessionElseEmpty(model_1, viewport_14.selectedSequence.sessionId), new FileMetadata(inputRecord_3.locationNames, inputRecord_3.siteNames, inputRecord_3.sequenceNames, updatedFrames_1, updatedFlaggedImagesList_1, inputRecord_3.frameToSequenceMap, inputRecord_3.sessionId)), model_1.sessionsFileMetadata), model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, (matchValue_19 = tryFind_1((s_25) => (s_25.sessionId === viewport_14.selectedSequence.sessionId), model_1.availableSessions), (matchValue_19 == null) ? model_1.sidetableSessionFilter : List_distinctBy((s_26) => s_26.sessionId, cons(matchValue_19, model_1.sidetableSessionFilter), {
                                Equals: (x_18, y_6) => (x_18 === y_6),
                                GetHashCode: stringHash,
                            })), model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_OfAsyncWith_attempt((x_19) => {
                                Cmd_OfAsync_start(x_19);
                            }, securedApi(token).unflagSessionImage, [viewport_14.selectedSequence.sessionId, frameNumber], (arg_49) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_49))))];
                        }
                    }
                }
                case 41: {
                    return item_3(viewportIndex_9, model_1.viewports).isPlaybackPaused ? [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, updateAt(viewportIndex_9, (inputRecord_4 = item_3(viewportIndex_9, model_1.viewports), new HistoricalViewport(inputRecord_4.selectedSequence, inputRecord_4.frameIndex, inputRecord_4.loadingState, inputRecord_4.loadedDicomFiles, inputRecord_4.thumbnailUrls, false, inputRecord_4.isLoadingAnnotations)), model_1.viewports), model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(46, viewportIndex_9)))] : [model_1, Cmd_none()];
                }
                case 42: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, updateAt(viewportIndex_10, (inputRecord_5 = item_3(viewportIndex_10, model_1.viewports), new HistoricalViewport(inputRecord_5.selectedSequence, inputRecord_5.frameIndex, inputRecord_5.loadingState, inputRecord_5.loadedDicomFiles, inputRecord_5.thumbnailUrls, true, inputRecord_5.isLoadingAnnotations)), model_1.viewports), model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_none()];
                }
                case 43: {
                    const viewport_16 = item_3(viewportIndex_11, model_1.viewports);
                    const newIndex_1 = (viewport_16.frameIndex + 1) | 0;
                    return (viewport_16.isPlaybackPaused ? true : (newIndex_1 > (length(item_3(viewport_16.selectedSequence.sequenceIndex, item_3(viewport_16.selectedSequence.siteIndex, item_3(viewport_16.selectedSequence.locationIndex, tryGetFileMetadataForSessionElseEmpty(model_1, viewport_16.selectedSequence.sessionId).frames)))) - 1))) ? [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, updateAt(viewportIndex_11, (inputRecord_6 = item_3(viewportIndex_11, model_1.viewports), new HistoricalViewport(inputRecord_6.selectedSequence, inputRecord_6.frameIndex, inputRecord_6.loadingState, inputRecord_6.loadedDicomFiles, inputRecord_6.thumbnailUrls, true, inputRecord_6.isLoadingAnnotations)), model_1.viewports), model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_none()] : [model_1, Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, (tupledArg_6 = [viewportIndex_11, newIndex_1], new PatientHistoricalComparisonMsg(11, tupledArg_6[0], tupledArg_6[1])))), Cmd_OfAsyncWith_result((x_20) => {
                        Cmd_OfAsync_start(x_20);
                    }, playbackTick(viewportIndex_11))]))];
                }
                case 44: {
                    const sessionScreenshotDetails = ofList(map_1((tupledArg_7) => [tupledArg_7[0], map_1((tuple_1) => tuple_1[1], tupledArg_7[1])], List_groupBy((tuple) => tuple[0], map_1((ci) => [item_3(ci.viewportIndex, model_1.viewports).selectedSequence.sessionId, new ScreenshotDetailViewModel(getFrameInformationString(model_1, ci.viewportIndex), getViewportName(model_1, ci.viewportIndex), "", ci.canvasData)], canvasInfo), {
                        Equals: (x_21, y_7) => (x_21 === y_7),
                        GetHashCode: stringHash,
                    })));
                    const selectedSessionNames = map_1((s_28) => s_28.sessionName, filter((s_27) => FSharpMap__ContainsKey(sessionScreenshotDetails, s_27.sessionId), model_1.availableSessions));
                    const selectedSessionName = (!isEmpty(selectedSessionNames)) ? (isEmpty(tail(selectedSessionNames)) ? head(selectedSessionNames) : "Multiple Sessions") : "";
                    const patternInput = init(new InitArgs(false, (inputRecord_7 = ScreenshotInfoViewModel_get_empty(), new ScreenshotInfoViewModel(inputRecord_7.screenshotId, sessionScreenshotDetails, `${selectedSessionName} - ${utcNow()}`, inputRecord_7.dateCreated))));
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, false, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, patternInput[0]), Cmd_map((arg_51) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(51, arg_51))), patternInput[1])];
                }
                case 45: {
                    const maybeScreenshotName = bind((m) => bind((s_29) => s_29.name, m.maybeScreenshotInfo), model_1.maybeScreenshotModalModel);
                    const matchValue_21 = [maybeS3Ctx, bind((m_1) => map((s_30) => s_30.sessionScreenshotDetails, m_1.maybeScreenshotInfo), model_1.maybeScreenshotModalModel), maybeScreenshotName];
                    let pattern_matching_result_4, ctx_1, sessionScreenshotDetails_2;
                    if (matchValue_21[2] == null) {
                        pattern_matching_result_4 = 0;
                    }
                    else if (matchValue_21[0] != null) {
                        if (matchValue_21[1] != null) {
                            if ((ctx = matchValue_21[0], !FSharpMap__get_IsEmpty(matchValue_21[1]))) {
                                pattern_matching_result_4 = 1;
                                ctx_1 = matchValue_21[0];
                                sessionScreenshotDetails_2 = matchValue_21[1];
                            }
                            else {
                                pattern_matching_result_4 = 2;
                            }
                        }
                        else {
                            pattern_matching_result_4 = 2;
                        }
                    }
                    else {
                        pattern_matching_result_4 = 2;
                    }
                    switch (pattern_matching_result_4) {
                        case 0: {
                            return [model_1, error("Screenshot name is required")];
                        }
                        case 1: {
                            const screenshotId = newGuid();
                            return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, map((m_2) => (new ScreenshotModalModel(m_2.isLoading, true, m_2.maybeScreenshotInfo)), model_1.maybeScreenshotModalModel)), Cmd_OfPromise_either(() => {
                                const pr_8 = mapIndexed((i_14, tupledArg_10) => {
                                    const sessionId_9 = tupledArg_10[0];
                                    const detail_1 = tupledArg_10[1];
                                    const s3Key = `${model_1.patient.id}/${sessionId_9}/Reports/Screenshots/${screenshotId}-${i_14}.png`;
                                    let pr_7;
                                    const pr_6 = dataUrlToBlob(value_28(detail_1.dataUrl));
                                    pr_7 = (pr_6.then((blob_1) => {
                                        let upload;
                                        const arg00_1 = S3_PutObjectRequestParams_get_create()(ofArray([new S3_PutObjectRequestParams(2, ctx_1.ActiveBucket), new S3_PutObjectRequestParams(3, s3Key), new S3_PutObjectRequestParams(1, blob_1)]));
                                        upload = ctx_1.S3Client.upload(arg00_1);
                                        return upload.promise();
                                    }));
                                    return pr_7.then((_arg24) => [sessionId_9, new ScreenshotDetailViewModel(detail_1.description, detail_1.locationDescription, s3Key, detail_1.dataUrl)]);
                                }, filter((tupledArg_9) => (tupledArg_9[1].dataUrl != null), collect_1((tupledArg_8) => map_1((d) => [tupledArg_8[0], d], tupledArg_8[1]), toList_1(sessionScreenshotDetails_2))));
                                return Promise.all(pr_8);
                            }, void 0, (dataArray) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(32, screenshotId, ofArray(dataArray)))), (arg_52) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_52))))];
                        }
                        case 2: {
                            return [model_1, Cmd_none()];
                        }
                    }
                }
                case 46: {
                    const matchValue_22 = bind((m_3) => m_3.maybeScreenshotInfo, model_1.maybeScreenshotModalModel);
                    if (matchValue_22 == null) {
                        return [model_1, Cmd_none()];
                    }
                    else {
                        const screenshotInfo = matchValue_22;
                        return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, map((m_4) => (new ScreenshotModalModel(m_4.isLoading, m_4.isSaving, new ScreenshotInfoViewModel(screenshotId_1, ofList(map_1((tupledArg_11) => [tupledArg_11[0], map_1((tuple_3) => tuple_3[1], tupledArg_11[1])], List_groupBy((tuple_2) => tuple_2[0], sessionScreenshotDetails_3, {
                            Equals: (x_22, y_8) => (x_22 === y_8),
                            GetHashCode: stringHash,
                        }))), screenshotInfo.name, screenshotInfo.dateCreated))), model_1.maybeScreenshotModalModel)), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(33)))];
                    }
                }
                case 47: {
                    const matchValue_23 = bind((m_5) => m_5.maybeScreenshotInfo, model_1.maybeScreenshotModalModel);
                    if (matchValue_23 != null) {
                        const screenshotInfo_1 = matchValue_23;
                        return [model_1, Cmd_OfAsyncWith_either((x_23) => {
                            Cmd_OfAsync_start(x_23);
                        }, securedApi(token).createScreenshotInfo, ScreenshotInfoViewModel__omitDataUrls(screenshotInfo_1), (arg_53) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(34, arg_53))), (arg_54) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_54))))];
                    }
                    else {
                        return [model_1, Cmd_none()];
                    }
                }
                case 48: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, void 0), Cmd_none()];
                }
                case 49: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, map((m_6) => (new ScreenshotModalModel(m_6.isLoading, false, m_6.maybeScreenshotInfo)), model_1.maybeScreenshotModalModel)), error(ErrorMessage_get_describe()(err_4))];
                }
                case 50: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, isLoading_1, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_none()];
                }
                case 51: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, void 0), Cmd_none()];
                }
                case 52: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, maybeViewportIndex_3, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_none()];
                }
                case 53: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, ofSeq(sessions), model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_none()];
                }
                case 54: {
                    return equals(model_1.layout, new ComparisonLayout(0)) ? [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, item_2, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, model_1.maybeScreenshotModalModel), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(36, 0)))] : [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, item_2, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, true, model_1.maybeScreenshotModalModel), infoPersistent("Select a viewport to display the image or click elsewhere to cancel this action")];
                }
                case 55: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, false, model_1.maybeScreenshotModalModel), Toastr_clearAll()];
                }
                case 56: {
                    const matchValue_24 = model_1.maybeScreenshotModalModel;
                    if (matchValue_24 == null) {
                        return [model_1, Cmd_none()];
                    }
                    else {
                        const patternInput_1 = update(void 0, msg_64, matchValue_24);
                        return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.availableSessions, model_1.sessionsFileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotationsForViewer, model_1.sessionAnnotationsForSidebar, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.selectedFlaggedImage, model_1.sessionAnnotationNoteState, model_1.sidetableSessionFilter, model_1.selectingViewport, patternInput_1[0]), Cmd_map((arg_56) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(51, arg_56))), patternInput_1[1])];
                    }
                }
            }
        });
    })));
}

